import { computed, reactive, readonly } from '@vue/composition-api';
import { StepDefinition } from '@/interfaces/step-definition';

type validationValue = boolean | undefined;

let validationMap;

export const setStepValidationMap = (steps: StepDefinition[]) => {
  validationMap = reactive(
    steps.reduce(
      (map, { id }) => ({
        ...map,
        [id]: undefined,
      }),
      {},
    ),
  );
};

export const getStepValidationMap = () => readonly(validationMap);

export const useFormValidation = (stepId: string) =>
  computed({
    get: () => validationMap[stepId],
    set: (value: validationValue) => {
      validationMap[stepId] = value;
    },
  });
