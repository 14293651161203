import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormMultipleChoiceField } from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';

export interface ParkingStepContext extends AssistantStepContext {
  parkingSpotOwner: FormMultipleChoiceValueType;
  parkingSpotType: FormMultipleChoiceValueType;
  spotHasPower: FormMultipleChoiceValueType;
  montageSpot: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('parkingSpotOwner', {
      component: {
        options: [
          {
            value: 'private',
          },
          {
            value: 'spaceForRent',
          },
          {
            value: 'communityProperty',
          },
        ],
        columns: 3,
        verticalOptionLayout: true,
        singleAnswer: true,
      },
      required: true,
      showHelperDialog: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'parking.formFields.parkingSpotOwner.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormMultipleChoiceField('parkingSpotType', {
      component: {
        options: [
          {
            value: 'garage',
          },
          {
            value: 'parkingSpace',
          },
          {
            value: 'carport',
          },
          {
            value: 'undergroundSpace',
          },
        ],
        singleAnswer: true,
        columns: 4,
        verticalOptionLayout: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'parking.formFields.parkingSpotType.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormMultipleChoiceField('spotHasPower', {
      component: {
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
          {
            value: 'dontKnow',
          },
        ],
        singleAnswer: true,
        columns: 3,
        verticalOptionLayout: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'parking.formFields.spotHasPower.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormMultipleChoiceField('montageSpot', {
      component: {
        options: [
          {
            value: 'wall',
          },
          {
            value: 'stele',
          },
        ],
        singleAnswer: true,
        columns: 3,
        verticalOptionLayout: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'parking.formFields.montageSpot.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
  ],
  id: StepId.PARKING,
};

export default config;
