





































import { computed, defineComponent } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { useFormValidation } from '@/lib/navigation/forward';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = useFormValidation(props.step?.id as string);

    const showBackButton = () =>
      props.step && props.steps && props.steps.indexOf(props.step) > 0;

    const showRequiredHint = computed<boolean>(
      (): boolean =>
        process.env.VUE_APP_SHOW_REQUIRED_HINT === 'true' &&
        Boolean(
          props.step?.fields &&
            props.step?.fields.some((field) => field.required === true),
        ),
    );

    const onTransition = (data) => {
      emit('transition', data);
    };

    return {
      formData,
      formValid,
      onNext,
      onPrev,
      onTransition,
      showBackButton,
      showRequiredHint,
    };
  },
});
