



































































































import { defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import GcDialog from '@/components/primitives/GcDialog.vue';
import { checkPostalCodeDE, checkRequired } from '@/lib/validators';
import zipCodesKasselArea from '~/consts/zipCodesKasselArea';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcTextField from '@/components/primitives/GcTextField.vue';
import GcCard from '@/components/primitives/GcCard.vue';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
    GcCard,
    GcDialog,
    GcRow,
    GcCol,
    GcTextField,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const showBackButton = (): boolean | undefined =>
      props.step && props.steps && props.steps.indexOf(props.step) > 0;

    const zipCodeModalOpen = ref(false);

    const zipCodeRules = [checkRequired, checkPostalCodeDE];

    const zipCodeInput = (value: string) => {
      if (
        checkPostalCodeDE(value) === true &&
        !zipCodesKasselArea.includes(Number(value))
      ) {
        zipCodeModalOpen.value = true;
      }
    };

    return {
      formData,
      formValid,
      onNext,
      onPrev,
      showBackButton,
      zipCodeInput,
      zipCodeModalOpen,
      zipCodeRules,
    };
  },
});
