

































































































































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import {
  AutocompleteItem,
  FormCheckboxComponentConfig,
  FormSelectComponentConfig,
  FormSwitchComponentConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import { FormFieldType } from '@/consts/input-types';
import HelperTextButton from '@/components/HelperTextButton.vue';
import GcAutocomplete from '@/components/primitives/GcAutocomplete.vue';
import GcCheckbox from '@/components/primitives/GcCheckbox.vue';
import GcDatePicker from '@/components/primitives/GcDatePicker.vue';
import GcSelect from '@/components/primitives/GcSelect.vue';
import GcSlider from '@/components/primitives/GcSlider.vue';
import GcSwitch from '@/components/primitives/GcSwitch.vue';
import GcTextField from '@/components/primitives/GcTextField.vue';
import GcTextArea from '@/components/primitives/GcTextArea.vue';
import GcTimePicker from '@/components/primitives/GcTimePicker.vue';
import MultipleChoice from '@/components/MultipleChoice.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import GcDropzone from '@/components/primitives/GcDropzone.vue';
import GcImage from '@/components/primitives/GcImage.vue';
import { getValidators } from '@/lib/forms/validators';
import { AssistantContext } from '~/steps/steps';
import HelperDialog from '@/components/HelperDialog.vue';
import {
  getComponentLabelString,
  getOptionLabelString,
} from '@/lib/forms/helper';
import GcTailwindTooltip from '@/components/primitives/GcTailwindTooltip.vue';
import { TooltipPositions } from '@/consts/tooltip-positions';
import i18n from '@/plugins/i18n';

export default defineComponent({
  components: {
    GcAlert,
    GcAutocomplete,
    GcCheckbox,
    GcDatePicker,
    GcDropzone,
    GcImage,
    GcSelect,
    GcSlider,
    GcSwitch,
    GcTextArea,
    GcTextField,
    GcTimePicker,
    HelperDialog,
    HelperTextButton,
    MultipleChoice,
    GcTailwindTooltip,
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
    },
    fieldName: {
      required: true,
      type: String,
    },
    formData: {
      default: () => ({}),
      required: true,
      type: Object as PropType<{ [key: string]: string }>,
    },
    hideLabel: {
      default: false,
      type: Boolean,
    },
    step: {
      default: () => ({}),
      type: Object as PropType<StepDefinition>,
    },
    autocompleteItems: {
      type: Array as PropType<string[] | number[] | AutocompleteItem[]>,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { emit }) => {
    const helpVisible = ref(false);

    const tooltipText = ref('');

    const tooltipPosition: string =
      props.step.tooltipPosition ?? TooltipPositions.BOTTOM;

    const field = computed(() =>
      props.step.fields?.find((f) => f.name === props.fieldName),
    );
    const fieldLabelString = computed(
      () => `${props.step.id}.formFields.${props.fieldName}.label`,
    );

    if (!field.value) {
      throw Error('Could not find form field ' + props.fieldName);
    }

    const controlComponentMap: Record<number, string> = {
      [FormFieldType.Checkbox]: 'gc-checkbox',
      [FormFieldType.DatePicker]: 'gc-date-picker',
      [FormFieldType.MultipleChoice]: 'multiple-choice',
      [FormFieldType.Select]: 'gc-select',
      [FormFieldType.Slider]: 'gc-slider',
      [FormFieldType.Switch]: 'gc-switch',
      [FormFieldType.TextArea]: 'gc-text-area',
      [FormFieldType.TextField]: 'gc-text-field',
      [FormFieldType.TimePicker]: 'gc-time-picker',
      [FormFieldType.FileUpload]: 'gc-dropzone',
      [FormFieldType.Autocomplete]: 'gc-autocomplete',
    };
    const controlComponent = computed(
      () => field.value && controlComponentMap[field.value.type],
    );

    // This label is used to get translated labels if needed within component like VCheckbox
    const controlComponentLabelString = computed(() =>
      field.value?.component &&
      (
        field.value.component as
          | FormCheckboxComponentConfig
          | FormSwitchComponentConfig
      ).showLabel
        ? getComponentLabelString(props.step.id, props.fieldName)
        : '',
    );

    // This label is used to get translated items if needed within component like VSelect or VAutocomplete
    const controlComponentItems = computed(() => {
      if (field.value?.component) {
        if ((field.value.component as FormSelectComponentConfig).items) {
          return (field.value.component as FormSelectComponentConfig).items
            .map((item) =>
              getOptionLabelString(props.step.id, props.fieldName, item),
            )
            .map((item) => i18n.t(item));
        }
      }
      if (props.autocompleteItems) {
        return props.autocompleteItems;
      }
      return undefined;
    });

    const fieldIsVisible = (): boolean => {
      if (!field.value?.isVisible) {
        return true;
      }

      return field.value?.isVisible(props.assistantContext);
    };

    const fieldIsDisabled = (): boolean => {
      if (!field.value?.isDisabled) {
        return false;
      }

      return field.value?.isDisabled(props.assistantContext);
    };

    const helperDialogWidth = computed(
      () => field.value?.helperDialog?.width ?? 500,
    );

    const helperDialogImagePath = computed(
      () => field.value?.helperDialog?.imagePath,
    );

    const showRequiredAsterisk =
      process.env.VUE_APP_SHOW_REQUIRED_ASTERISK === 'true';

    const getTooltipText = () => {
      const validators = (field.value && getValidators(field.value)) ?? [];
      for (const validator of validators) {
        const checkValueFromValidator: boolean | string =
          typeof validator === 'function' &&
          validator(props.formData[props.fieldName]);

        if (typeof checkValueFromValidator === 'string') {
          return checkValueFromValidator;
        } else if (checkValueFromValidator === false) {
          return 'No Error Message defined';
        }
      }
      return '';
    };

    const setTooltipText = () => {
      tooltipText.value = getTooltipText();
    };
    const unsetTooltipText = () => {
      tooltipText.value = '';
    };

    const controlValidators = computed(
      () => field.value && getValidators(field.value),
    );

    const emitNextStep = () => {
      emit('next-step');
    };

    const emitOnComponentChange = (event: Event) => {
      emit('component:change', event);
    };

    const emitOnComponentInput = (event: Event) => {
      emit('component:input', event);
    };

    const emitOnComponentBlur = (event: Event) => {
      setTooltipText();
      emit('component:blur', event);
    };

    const emitOnComponentFocus = (event: Event) => {
      unsetTooltipText();
      emit('component:focus', event);
    };

    const baseUrl = process.env.VUE_APP_BASE_URL;

    const fieldHelperDialogString = `${props.step.id}.formFields.${props.fieldName}.helperDialog`;

    return {
      baseUrl,
      FormFieldType,
      controlComponent,
      controlComponentItems,
      controlComponentLabelString,
      controlValidators,
      emitNextStep,
      emitOnComponentBlur,
      emitOnComponentFocus,
      emitOnComponentChange,
      emitOnComponentInput,
      field,
      fieldIsDisabled,
      fieldIsVisible,
      fieldLabelString,
      fieldHelperDialogString,
      helpVisible,
      helperDialogWidth,
      helperDialogImagePath,
      showRequiredAsterisk,
      setTooltipText,
      unsetTooltipText,
      tooltipText,
      tooltipPosition,
    };
  },
});
