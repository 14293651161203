import { matomoTrackEvent } from '@/lib/analytics/matomo';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { getProjectRequestDataForComment } from '@/lib/formatter/product-request';
import { AssistantContext } from '~/steps/steps';
import { sendProductRequest } from '@/lib/submit/send-product-request';
import { getFilesFromContext } from '@/lib/xstate/machine';
import { uploadFiles } from '@/lib/submit/upload-files';
import { getProductRequest } from '~/steps/summary/helper';
import { loadProductsByIds } from '@/lib/greenportal/load-products';
const config: StepDefinition = {
  hideSkipMessage: () => true,
  id: StepId.SUMMARY,
  submit: async ({ i18n, steps, assistantContext }): Promise<void> => {
    try {
      /*
    ###############################################################################
    ############################### preparation ###################################
    ###############################################################################
    */

      const contextFiles = getFilesFromContext(assistantContext);
      const s3UploadedFilePaths = await uploadFiles(contextFiles);

      /*
    ###############################################################################
    ########################### send product request ##############################
    ###############################################################################
    */

      const commentsData = getProjectRequestDataForComment(
        steps as StepDefinition[],
        assistantContext as AssistantContext,
      );
      const DEFAULT_PRODUCT_ID = process.env.VUE_APP_SHOP_PRODUCT_ID;
      const url = new URL(window.location.href);
      const productId =
        url.searchParams.get('product-id') ?? DEFAULT_PRODUCT_ID;
      const productRequest = getProductRequest(
        assistantContext,
        commentsData,
        productId,
      );

      // Get the name of the product to track it with matomo
      const productDetails = await loadProductsByIds([productId]);
      const productName =
        productDetails.length === 1 &&
        productDetails[0].name !== null &&
        productDetails[0].name.length > 0
          ? productDetails[0].name
          : '';

      if (productName.length === 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Couldn't get name of product with id ${productId}. Will track matomo event with the product url instead.`,
        );
      }

      await sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_SHOP_HOST,
        shopApiKey: process.env.VUE_APP_SHOP_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_SHOP_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      matomoTrackEvent(
        'Shop',
        'Anfrage',
        productName.length > 0 ? productName : productRequest.productUrl,
      );
    } catch (error) {
      let errorMessage = i18n.t('errorMessages.productRequestSend').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      throw new Error(errorMessage);
    }
  },
  type: StepTypes.SUMMARY,
};

export default config;
