<script>
import { VAutocomplete } from 'vuetify/lib';
export default VAutocomplete.extend({
  props: {
    dense: {
      default: true,
      type: [Boolean, String],
    },
    outlined: {
      default: true,
      type: [Boolean, String],
    },
    clearable: {
      default: true,
      type: Boolean,
    },
    appendIcon: {
      default: '',
    },
  },
});
</script>
