import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormCheckbox, buildFormTextField } from '@/lib/forms/builder';
import { StepTypes } from '@/consts/step-types';
import { ValidationType } from '@/consts/validation-types';
import i18n from '@/plugins/i18n';

export interface ContactFormStepContext extends AssistantStepContext {
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const outputFormatterWithBilling = (data) => {
  if (data.assistantContext.contactForm.checkBillingAddress) {
    return {
      formattedLabel: data.formattedLabel,
      formattedValue: data.formattedValue,
    };
  } else {
    return {
      formattedLabel: false,
      formattedValue: false,
    };
  }
};

const config: StepDefinition = {
  // If you set a Vue component for key "component" it will be used
  // instead of "DefaultStep.vue". This is helpful if you need more
  // customizations either visually or programmatically.
  // E.g. in this contact form we want the form fields displayed as a
  // grid and a privacy confirmation with a link to the privacy policy.
  fields: [
    buildFormTextField('firstname', {
      required: true,
    }),
    buildFormTextField('lastname', {
      required: true,
    }),
    buildFormTextField('street', {
      required: true,
    }),
    buildFormTextField('streetNumber', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormTextField('phone', {
      required: true,
      validation: [ValidationType.phoneNumber],
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormCheckbox('checkBillingAddress', {
      component: {
        showLabel: true,
      },
      hideLabel: true,
      outputFormatter: (data) => {
        if (data.assistantContext.contactForm.checkBillingAddress) {
          return {
            formattedLabel: data.formattedLabel,
            formattedValue: '',
          };
        } else {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
      },
    }),
    buildFormTextField('firstnameBilling', {
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormTextField('lastnameBilling', {
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormTextField('streetBilling', {
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormTextField('streetNumberBilling', {
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormTextField('zipcodeBilling', {
      validation: [ValidationType.postalCodeDE],
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormTextField('cityBilling', {
      outputFormatter: (data) => outputFormatterWithBilling(data),
      required: true,
    }),
    buildFormCheckbox('newsletterConfirmation', {
      hideLabel: true,
      outputFormatter: (data) => ({
        formattedLabel:
          data.target === 'email'
            ? (i18n.t(
                'contactForm.formFields.newsletterConfirmation.summaryHeadline',
              ) as string)
            : data.formattedLabel,
        formattedValue: data.formattedValue,
      }),
      showHelperDialog: true,
    }),
    buildFormCheckbox('privacyConfirmation', {
      hideLabel: true,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
      required: true,
    }),
  ],
  id: StepId.CONTACT_FORM,
  type: StepTypes.CONTACT_FORM,
  typeOptions: {
    contactForm: {
      showNewsletterConfirmation: () => true,
      showBillingAddress: () => true,
    },
  },
};

export default config;
