// Each step has its own identifier
export enum StepId {
  GENERAL = 'general',
  PARKING = 'parking',
  IMAGES_UPLOAD = 'imagesUpload',
  SPATIAL = 'spatial',
  CONTACT_FORM = 'contactForm',
  SUMMARY = 'summary',
  DONE = 'done',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}
