import { StepId } from '~/consts/assistant-steps';
import {
  FormUploadFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormFileUploadField } from '@/lib/forms/builder';

export interface ImagesUploadStepContext extends AssistantStepContext {
  uploadImages: FormUploadFieldValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormFileUploadField('uploadImages', {
      component: {
        accept: 'image/*',
        multiple: true,
      },
      showDescription: true,
      hideLabel: true,
      outputFormatter: (data) => {
        if (data.target === 'email') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: data.formattedLabel,
          formattedValue: data.formattedValue,
        };
      },
    }),
  ],
  id: StepId.IMAGES_UPLOAD,
};

export default config;
