import axios from 'axios';

export const getApiToken = async (): Promise<string> => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/apiToken`);
    return response.data.apiToken;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occurred while requesting an apiToken.');
  }
};
