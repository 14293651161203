export enum FormFieldType {
  TextField,
  Checkbox,
  Combobox,
  DatePicker,
  TimePicker,
  TextArea,
  NumberForm,
  FileUpload,
  SectionLabel,
  SectionTitle,
  Divider,
  MultipleChoice,
  Slider,
  Select,
  Alert,
  Switch,
  Autocomplete,
}
