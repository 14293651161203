






























import { defineComponent } from '@vue/composition-api';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import GcButton from '@/components/primitives/GcButton.vue';
import RestoreAlert from '@/components/RestoreAlert.vue';
import { useResponsiveBreakpoints } from '@/lib/ui-helper/responsive';
export default defineComponent({
  components: {
    GcButton,
    RestoreAlert,
  },
  setup: (_, { emit }) => {
    const startAssistant = () => {
      emit('start');
    };

    const baseUrl = process.env.VUE_APP_BASE_URL;

    const showDeviceChangeQrCode =
      process.env.VUE_APP_SHOW_DEVICE_CHANGE_QR_CODE === 'true';

    const { isMd } = useResponsiveBreakpoints();

    return {
      baseUrl,
      isMd,
      showDeviceChangeQrCode,
      faAngleRight,
      startAssistant,
    };
  },
});
