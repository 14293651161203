import { reactive, readonly } from '@vue/composition-api';

const globalState = reactive({
  submitting: false,
});
const setSubmitting = (value) => {
  globalState.submitting = value;
};
export const useState = () => ({
  globalState: readonly(globalState),
  setSubmitting,
});
