import {
  faBuilding,
  faBuildings,
  faHouseBuilding,
  faHouseReturn,
  faHouseUser,
} from '@fortawesome/pro-duotone-svg-icons';
import component from './GeneralForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import zipcodes from '~/consts/zipCodesKasselArea';
import i18n from '@/plugins/i18n';
import { ValidationType } from '@/consts/validation-types';

export interface GeneralStepContext extends AssistantStepContext {
  zipCode: FormTextFieldValueType;
  zipCodeInKasselArea: FormCheckboxValueType;
  wallboxCount: FormMultipleChoiceValueType;
  housingSituation: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  beforeNext: (context) => {
    if (!zipcodes.includes(Number(context.general.zipCode))) {
      context.general.zipCodeInKasselArea = false;
    }
    return true;
  },
  component,
  fields: [
    buildFormTextField('zipCode', {
      required: true,
      showDescription: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'general.formFields.zipCode.formattedLabel',
        ) as string,
        formattedValue: !data.assistantContext.general.zipCodeInKasselArea
          ? `${data.formattedValue} (${i18n.t(
              'general.formFields.zipCode.notInKasselAreaHint',
            )})`
          : data.formattedValue,
      }),
    }),
    buildFormCheckbox('zipCodeInKasselArea', {
      default: true,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
    }),
    buildFormMultipleChoiceField('wallboxCount', {
      component: {
        columns: 4,
        options: [
          {
            value: 'one',
          },
          {
            value: 'two',
          },
          {
            value: 'three',
          },
          {
            value: 'moreThanThree',
          },
        ],
        singleAnswer: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'general.formFields.wallboxCount.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormTextField('charger11kw', {
      component: {
        type: 'number',
      },
      validation: [ValidationType.numeric],
    }),
    buildFormTextField('charger22kw', {
      component: {
        type: 'number',
      },
      validation: [ValidationType.numeric],
    }),
    buildFormMultipleChoiceField('housingSituation', {
      component: {
        columns: 5,
        options: [
          {
            icon: faHouseUser,
            value: 'detached',
          },
          {
            icon: faHouseReturn,
            value: 'rentalApartment',
          },
          {
            icon: faHouseBuilding,
            value: 'condominium',
          },
          {
            icon: faBuilding,
            value: 'rentalProperty',
          },
          {
            icon: faBuildings,
            value: 'commercialSolution',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      required: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'general.formFields.housingSituation.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
  ],
  id: StepId.GENERAL,
};

export default config;
