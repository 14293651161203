import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormMultipleChoiceField,
  buildFormTextField,
} from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';

export interface SpatialStepContext extends AssistantStepContext {
  cableLength: FormTextFieldValueType;
  wallBreakthroughs: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  beforeNext: (context) => {
    if (!context.spatial.wallBreakthroughs.includes('moreThanFive')) {
      context.spatial.wallBreakthroughsMoreThanFive = '–';
    }

    // If we don't return true, the assistant won't go to next step.
    return true;
  },
  fields: [
    buildFormTextField('cableLength', {
      component: {
        type: 'number',
        suffix: i18n.t('spatial.formFields.cableLength.suffix') as string,
        min: 0,
      },
      required: true,
      showHelperDialog: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'spatial.formFields.cableLength.formattedLabel',
        ) as string,
        formattedValue: `${data.formattedValue} ${i18n.t(
          'spatial.formFields.cableLength.suffix',
        )}`,
      }),
    }),
    buildFormMultipleChoiceField('wallBreakthroughs', {
      component: {
        options: [
          {
            image: {
              file: './wallbox-installation/zero.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'zero',
          },
          {
            image: {
              file: './wallbox-installation/one.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'one',
          },
          {
            image: {
              file: './wallbox-installation/two.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'two',
          },
          {
            image: {
              file: './wallbox-installation/three.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'three',
          },
          {
            image: {
              file: './wallbox-installation/four.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'four',
          },
          {
            image: {
              file: './wallbox-installation/five.svg',
              options: {
                height: 100,
                width: 150,
              },
            },
            value: 'five',
          },
          {
            value: 'moreThanFive',
          },
        ],
        singleAnswer: true,
        columns: 3,
      },
      required: true,
      showHelperDialog: true,
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'spatial.formFields.wallBreakthroughs.formattedLabel',
        ) as string,
        formattedValue: data.formattedValue,
      }),
    }),
    buildFormTextField('wallBreakthroughsMoreThanFive', {
      component: {
        min: 6,
        suffix: i18n.t(
          'spatial.formFields.wallBreakthroughsMoreThanFive.suffix',
        ) as string,
        type: 'number',
      },
      validation: [
        {
          errorLabel: 'wallBreakthroughsMoreThanFive.minValue',
          validator: (value) => Number(value) > 5,
        },
      ],
      outputFormatter: (data) => {
        if (data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        } else {
          return {
            formattedLabel: data.formattedLabel,
            formattedValue: data.formattedValue,
          };
        }
      },
      isVisible: (context) =>
        context.spatial.wallBreakthroughs.includes('moreThanFive'),
    }),
  ],
  id: StepId.SPATIAL,
};

export default config;
