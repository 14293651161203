import { Buffer } from 'buffer';
import axios from 'axios';

import { getApiToken } from './api-token';

const uploadFile = async (apiToken, file: File): Promise<string> => {
  const filePath = `${Date.now()}/${file.name}`;
  try {
    const fileData = {
      s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
      filePath,
      fileType: file.type,
    };

    const base64EncodedFileData = Buffer.from(
      JSON.stringify(fileData),
      'utf8',
    ).toString('base64');

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/signedUploadUrl`,
      {
        data: base64EncodedFileData,
      },
      {
        headers: { 'X-API-Token': apiToken },
      },
    );

    const { signedUploadUrl } = response.data;

    if (!signedUploadUrl) {
      throw new Error('No signed upload url found in api-response');
    }

    await axios.put(signedUploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    // The filePath will be automatically prefixed with /upload by the api function.
    return `upload/${filePath}`;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error(
      `An error occured while uploading the file '${file.name}' to to s3-bucket`,
    );
  }
};

export const uploadFiles = async (
  files: Array<File>,
): Promise<Array<string>> => {
  try {
    const apiToken = await getApiToken();

    const uploadedFilePaths = await Promise.all(
      files.map(
        async (file): Promise<string> => await uploadFile(apiToken, file),
      ),
    );

    return uploadedFilePaths;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occured while uploading files to s3-bucket');
  }
};
