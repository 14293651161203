import {
  AnyEventObject,
  StatesConfig,
  TransitionConfig,
  TransitionsConfig,
} from 'xstate';
import { setStepValidationMap } from '@/lib/navigation/forward';
import { AssistantContext } from '~/steps/steps';
import {
  JUMP_TO_CONTACTFORM,
  NEXT,
  PREV,
} from '@/consts/assistant-state-events';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';

export const buildStates = (
  steps: Array<StepDefinition>,
  context: AssistantContext, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StatesConfig<AssistantContext, any, AnyEventObject> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stateMap: StatesConfig<AssistantContext, any, AnyEventObject> = {};

  steps.forEach((step: StepDefinition, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let transitions: TransitionsConfig<AssistantContext, any> = {};

    if (
      index > 0 &&
      (index < steps.length - 1 || step.type !== StepTypes.FINAL)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prevTransition: TransitionConfig<AssistantContext, any>[] = [];

      let prevIndex = index - 1;
      while (typeof steps[prevIndex] !== 'undefined') {
        const isDisabled = steps[prevIndex].isDisabled;
        if (!isDisabled) {
          prevTransition.push({ target: steps[prevIndex].id });
          break;
        }

        prevTransition.push({
          cond: () => !isDisabled(context),
          target: steps[prevIndex].id,
        });

        prevIndex--;
      }

      transitions = {
        ...transitions,
        [PREV]: prevTransition,
      };
    }
    if (index < steps.length - 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextTransition: TransitionConfig<AssistantContext, any>[] = [];

      let nextIndex = index + 1;
      while (typeof steps[nextIndex] !== 'undefined') {
        const isDisabled = steps[nextIndex].isDisabled;
        if (!isDisabled) {
          nextTransition.push({ target: steps[nextIndex].id });
          break;
        }

        nextTransition.push({
          cond: () => !isDisabled(context),
          target: steps[nextIndex].id,
        });

        nextIndex++;
      }

      transitions = {
        ...transitions,
        [NEXT]: nextTransition,
      };
    }

    // enable go to any step from current only if step not disabled
    steps
      .filter((stepItem) => stepItem !== step)
      .forEach((stepItem) => {
        if (stepItem.isDisabled || stepItem.type === StepTypes.FINAL) {
          return;
        }

        const JUMP_TO_STEP_ITEM =
          stepItem.type === StepTypes.CONTACT_FORM
            ? JUMP_TO_CONTACTFORM
            : 'JUMP_TO_' + stepItem.id.toUpperCase();

        transitions = {
          ...transitions,
          [JUMP_TO_STEP_ITEM]: {
            target: stepItem.id,
          },
        };
      });

    // enable go to previous steps from current only if step not disabled
    if (step.type !== StepTypes.FINAL) {
      steps
        .filter((stepItem) => stepItem !== step)
        .slice(0, index)
        .forEach((stepItem) => {
          const isDisabled = stepItem.isDisabled;
          if (!isDisabled) {
            transitions = {
              ...transitions,
              ['BACK_TO_' + stepItem.id.toUpperCase()]: {
                target: stepItem.id,
              },
            };
          } else {
            transitions = {
              ...transitions,
              ['BACK_TO_' + stepItem.id.toUpperCase()]: {
                cond: () => !isDisabled(context),
                target: stepItem.id,
              },
            };
          }
        });
    }

    stateMap[step.id] = {
      on: { ...transitions, ...step.transitions },
    };

    if (step.type === StepTypes.FINAL) {
      stateMap[step.id].type = 'final';
    }
  });

  setStepValidationMap(steps);

  return stateMap;
};
