





























































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { AssistantContext } from '~/steps/steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);

    const showBusinessCustomerFields = computed<boolean>(() =>
      props.step?.typeOptions?.contactForm?.isBusinessCustomer
        ? props.step.typeOptions.contactForm.isBusinessCustomer(
            props.assistantContext as AssistantContext,
          )
        : false,
    );

    const showCustomerNumberField = computed<boolean>(() =>
      props.step?.typeOptions?.contactForm?.showCustomerNumber
        ? props.step.typeOptions.contactForm.showCustomerNumber(
            props.assistantContext as AssistantContext,
          )
        : false,
    );

    const showNewsletterConfirmationField = computed<boolean>(() =>
      props.step?.typeOptions?.contactForm?.showNewsletterConfirmation
        ? props.step.typeOptions.contactForm.showNewsletterConfirmation(
            props.assistantContext as AssistantContext,
          )
        : false,
    );

    const showBillingAddress = computed<boolean>(() =>
      props.step?.typeOptions?.contactForm?.showBillingAddress
        ? props.step.typeOptions.contactForm.showBillingAddress(
            props.assistantContext as AssistantContext,
          )
        : false,
    );

    const showBillingAddressFields = computed<boolean>(
      () => showBillingAddress.value && formData.value.checkBillingAddress,
    );

    const showRequiredHint = computed<boolean>(
      (): boolean =>
        process.env.VUE_APP_SHOW_REQUIRED_HINT === 'true' &&
        Boolean(
          props.step?.fields &&
            props.step?.fields.some((field) => field.required === true),
        ),
    );

    return {
      formData,
      formValid,
      onNext,
      onPrev,
      showBusinessCustomerFields,
      showCustomerNumberField,
      showNewsletterConfirmationField,
      showBillingAddress,
      showBillingAddressFields,
      showRequiredHint,
    };
  },
});
