/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description:
      'Gerne helfen wir ihnen auf Ihrem Weg in die Elektromobilität. Um Sie optimal beraten zu können benötigen wir einige Informationen von Ihnen. Dies sind unter anderem ein Foto Ihres Zählerschranks und ein Foto des gewünschen Austellorts der Wallbox/Ladesäule. Zusätzlich werden  Sie einen Zollstock benötigen, um die Strecke zwischen Zählerschrank und Aufstellort zu messen.',
    title: 'Wallbox Installationsassistent',
    step: {
      label: 'Start',
    },
  },
  general: {
    step: {
      label: 'Allgemein',
    },
    title: 'Allgemeine Angaben',
    formFields: {
      zipCode: {
        label:
          'In welchem PLZ-Gebiet möchten Sie Ihre Ladelösung installieren?',
        formattedLabel: 'PLZ-Gebiet',
        notInKasselAreaHint: 'Außerhalb der verfügbaren Gebietes',
      },
      wallboxCount: {
        label: 'Wie viele Ladestationen benötigen Sie?',
        formattedLabel: 'Anzahl Ladestationen',
        options: {
          one: {
            label: '1 Ladestation',
          },
          two: {
            label: '2 Ladestationen',
          },
          three: {
            label: '3 Ladestationen',
          },
          moreThanThree: {
            label: 'Mehrere Ladestation',
          },
        },
      },
      charger11kw: {
        label:
          'Wie viele Ladestationen davon sollen mit 11kW betrieben werden?',
      },
      charger22kw: {
        label:
          'Wie viele Ladestationen davon sollen mit 22kW betrieben werden?',
      },
      housingSituation: {
        label: 'Wie ist Ihre Wohnsituation?',
        formattedLabel: 'Wohnsituation',
        options: {
          detached: {
            label: 'Einfamilienhaus',
          },
          rentalApartment: {
            label: 'Wohnung zur Miete',
          },
          condominium: {
            label: 'Eigentumswohnung',
          },
          rentalProperty: {
            label: 'Mietobjekt',
          },
          commercialSolution: {
            label: 'Ich suche nach einer gewerblichen Lösung',
          },
        },
      },
    },
    zipCodeError: {
      modal: {
        label: 'Unser Angebot ist an Ihrer Postleitzahl nicht verfügbar.',
        description:
          'Sie können uns jedoch gerne trotzdem Ihre Daten zukommen lassen, damit wir Ihre Anfrage prüfen können. Gerne können Sie uns auch eine Mail an <a class="gc-text-primary" href="mailto:emobil@sw-kassel.de">emobil@sw-kassel.de</a> schicken.',
      },
    },
  },
  parking: {
    step: {
      label: 'Parksituation',
    },
    title: 'Parksituation',
    formFields: {
      parkingSpotOwner: {
        helperDialog: {
          text: 'Private Ladelösungen können wir nur an gemieteten oder in ihrem Eigentum befindlichen Parkflächen anbieten.',
        },
        label: 'Wie ist der Parkplatz an dem Sie laden möchten?',
        formattedLabel: 'Zugehörigkeit',
        options: {
          private: {
            label: 'Privates Eigentum',
          },
          spaceForRent: {
            label: 'Abstellmöglichkeit zur Miete',
          },
          communityProperty: {
            label: 'Gemeinschaftsbesitz ',
          },
        },
      },
      parkingSpotType: {
        label: 'Welche Art Parkplatz ist es?',
        formattedLabel: 'Parkplatz-Typ',
        options: {
          garage: {
            label: 'Garage',
          },
          parkingSpace: {
            label: 'Stellplatz',
          },
          carport: {
            label: 'Carport',
          },
          undergroundSpace: {
            label: 'Tiefgaragenstellplatz',
          },
        },
      },
      spotHasPower: {
        label: 'Gibt es Strom am Parkplatz?',
        formattedLabel: 'Strom verfügbar',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
          dontKnow: {
            label: 'Ich weiß es nicht',
          },
        },
      },
      montageSpot: {
        label: 'Wo soll die Wallbox montiert werden?',
        formattedLabel: 'Art der Montage',
        options: {
          wall: {
            label: 'Wandmontage',
          },
          stele: {
            label: 'Montage auf Stele',
          },
        },
      },
    },
  },
  imagesUpload: {
    step: {
      label: 'Bilder hochladen',
    },
    title: 'Bilder hochladen (optional)',
    formFields: {
      uploadImages: {
        label: 'Bilder-Uploads',
        htmlDescription:
          'Bitte stellen Sie uns folgende Bilder zur Verfügung: <br> - Ein Foto Ihres Zählerschranks auf dem die Sicherungen etc. zu erkennen sind. <br> - Ein Foto des Aufstellortes Ihrer Wallbox',
      },
    },
  },
  spatial: {
    title: 'Bauliche Gegebenheiten',
    step: {
      label: 'Bauliche Gegebenheiten',
    },
    formFields: {
      cableLength: {
        label:
          'Wie viele Meter sind es von dem Zählerkasten zum Aufstellort der Wallbox?',
        formattedLabel:
          'Entfernung des Zählerkastens zum Aufstellort der Wallbox',
        helperDialog: {
          title: 'Wie ist zu messen?',
          text: 'Messen Sie die Strecke mithilfe von einem Maßband oder Zollstock vom Verteilerkasten zur Außenwand. Bitte messen Sie dabei gerade und an den Wänden entlang.',
        },
        suffix: 'm',
      },
      wallBreakthroughs: {
        label:
          'Wie viele Mauerdurchbrüche wären für die Kabelführung vom Zählerkasten zum Aufstellort der Wallbox nötig? ',
        formattedLabel: 'Anzahl nötiger Mauerdurchbrüche',
        helperDialog: {
          title: 'Wieso fragen wir nach Wanddurchbrüchen?',
          text: 'Um das Kabel zwischen Wallbox und Verteilerkasten zu verlegen, ist es in der Regel erforderlich das Kabel durch Wände hindurch zu verlegen. Bitte teilen Sie uns hier die Anzahl der Wände mit, durch die das Kabel geführt werden muss. Dies wird in der Angebotskalkulation berücksichtigt.',
        },
        options: {
          zero: {
            label: '0',
          },
          one: {
            label: '1',
          },
          two: {
            label: '2',
          },
          three: {
            label: '3',
          },
          four: {
            label: '4',
          },
          five: {
            label: '5',
          },
          moreThanFive: {
            label: 'Mehr als 5',
          },
        },
      },
      wallBreakthroughsMoreThanFive: {
        label: 'Wie viele Wanddurchbrüche sind notwendig?',
        suffix: 'Wände',
      },
    },
  },
  contactForm: {
    formFields: {
      privacyConfirmation: {
        headline:
          'Hiermit stimme ich der <a href="https://www.sw-kassel.de/privatkunden/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
      },
      newsletterConfirmation: {
        helperDialog: {
          text: 'Angebote und individuelle Vorteile: z.B. zu Strom- und Gasprodukten, Fernwärme, Energiedienstleistungen, ÖPNV, Telekommunikation und den Bäder der Städtische Werke AG sowie der ihr verbundenen Unternehmen der Kasseler Verkehrs- und Versorgungs-GmbH. Je nach Thema und Wichtigkeit informieren wir Sie per Brief, E-Mail oder telefonisch. Diesen praktischen Infoservice können Sie jederzeit widerrufen.',
        },
      },
    },
  },
  errorMessages: {
    wallBreakthroughsMoreThanFive: {
      minValue: 'Anzahl von Wanddurchbrüchen soll mehr als 5 sein.',
    },
    productRequestSend: 'productRequest data send error',
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
