import done from '~/steps/done';
import general, { GeneralStepContext } from '~/steps/general';
import parking, { ParkingStepContext } from '~/steps/parking';
import imagesUpload, { ImagesUploadStepContext } from '~/steps/imagesUpload';
import spatial, { SpatialStepContext } from '~/steps/spatial';
import contactForm, { ContactFormStepContext } from '~/steps/contact-form';
import summary from '~/steps/summary';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [
  general,
  parking,
  imagesUpload,
  spatial,
  contactForm,
  summary,
  done,
];

// Add the imported context interface for the steps
export interface AssistantContext {
  general: GeneralStepContext;
  parking: ParkingStepContext;
  imagesUpload: ImagesUploadStepContext;
  spatial: SpatialStepContext;
  contactForm: ContactFormStepContext;
}

export default steps;
